@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}
a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Inherits the color from its parent element */
}
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Define the styles for the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #d3d3d3; /* Light grey color for the thumb */
  border-radius: 4px; /* Rounded corners */
}

/* Define the styles for the scrollbar track on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0; /* Lighter grey on hover */
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}
/* input:focus {
  outline: none; 
  border: none; 

} */

/* Additional styles for MUI Input components */
.MuiInput-root,
.MuiInput-input {
  border-bottom: none !important;
}

.fc-event{
  cursor: pointer;
}

.fc .fc-col-header-cell {
  background-color: #f7f7f7; /* Light grey background */
  color: #333333; /* Dark grey text color */
  border-right: 1px solid #e0e0e0; /* Light grey border for separation */
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
}

/* To remove the right border on the last header cell */
.fc .fc-col-header-cell:last-child {
  border-right: none;
}

/* To modify the padding and font size for the header text */
.fc .fc-col-header-cell-cushion {
  padding: 0;
  font-size: 16px;
}

.fc .fc-col-header-cell.fc-day-today {
  background-color: #eaf6ea; /* Slightly green-tinted background for the current day */
}


/* General button styles */
.fc .fc-button {
  background-color: #ffffff; /* White background */
  color: #333333; /* Dark text for contrast */
  border: 1px solid #dddddd; /* Light gray border */
  padding: 8px 12px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px; /* Slight rounding of corners */
  font-size: 14px;
  font-weight: normal;
}

/* Button hover styles */
.fc .fc-button:hover {
  background-color: #f7f7f7; /* Slightly darker background on hover */
  border-color: #cccccc; /* Darker border on hover */
}

/* Button active (clicked) styles */
.fc .fc-button:active {
  background-color: #e6e6e6; /* Even darker background when active */
  border-color: #bbbbbb; /* Even darker border when active */
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); /* Inner shadow for a "pressed" effect */
}

/* Button focus styles */
.fc .fc-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Bootstrap-like focus shadow */
  outline: 0; /* Removing default focus outline */
}

/* Event styles */
.fc .fc-event {
  background-color: #44b700; /* Green background for events */
  border: none; /* No border by default, but you could set one */
  color: #ffffff; /* White text for contrast */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 4px; /* Rounded corners */
  padding: 4px 8px; /* Some padding inside the event */
  font-size: 14px; /* Adjust font size as needed */
  line-height: 1.4; /* Line height for better readability */
}

/* Event title styles */
.fc .fc-event-title {
  font-weight: bold; /* Bold for the event title */
}

/* Event time styles */
.fc .fc-event-time {
  font-size: 12px; /* Smaller font size for the event time */
}

/* Change the font color for the header, including buttons */
.fc .fc-toolbar-title,
.fc .fc-button {
  color: #333; /* Dark grey color for header text and buttons */
}

/* Change the font color for day names */
.fc .fc-col-header-cell-cushion {
  color: #333; /* Dark grey color for day names */
}

/* Change the font color for the dates in the month view */
.fc .fc-daygrid-day-number {
  color: #333; /* Dark grey color for dates */
}

/* Change the font color for event titles */
.fc .fc-event-title,
.fc .fc-event-time {
  color: #fff; /* Assuming events have a darker background, use white for legibility */
}

/* If the calendar has time slots in a time grid, change those as well */
.fc .fc-timegrid-slot-label-cushion {
  color: #333; /* Dark grey color for time labels */
}

.fc .fc-toolbar-title {
  margin-left: 15px; /* Adjust this value as needed */
  font-size: x-large;
  font-weight: normal;
}

.fc .fc-timegrid .fc-day-today  {
  background-color: rgb(244, 244, 244) !important;
}

.fc-header-toolbar .fc-toolbar-chunk .fc-button {
  margin-right: 5px;
}